import React from 'react';
import _ from 'underscore';
import { withTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { Divider, Menu } from 'semantic-ui-react';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import withEmailContextConsumer from '../../hocs/email/withEmailContextConsumer';
import withUserFromJWToken from '../../hocs/users/withUserFromJWToken';
import withClientBilling from '../../hocs/clients/withClientBilling';
import withClientDetails from '../../hocs/clients/withClientDetails';
import withActionLogger from '../../hocs/withActionLogger';

import UserInitialThumbnail from '../UserInitialThumbnail';

import './SettingsMenu.css';

const SettingsMenu = ({
  clientId,
  currentPage,
  onLogAction,
  client,
  user,
  emailApi,
  t,
}) => {
  const logAction = ({ type }) => {
    onLogAction({
      type,
      info: {
        clientId,
        from: 'settings-menu',
      },
    });
  };
  const { applicationMode } = useNavApplicationMode();

  const configurationParams = useMergedConfigurationParams();
  const { permissions } = useClientPermissions(clientId);
  const matchPipelinePage = useRouteMatch({
    path: '/client/:clientId/pipeline/:missionCategory',
  });

  const missionCategory =
    matchPipelinePage?.params.missionCategory || 'recruiting';
  const username =
    `${user?.firstname || ''} ${user?.lastname || ''}`.trim() ||
    client?.title ||
    '';

  return (
    <div className='settings-menu'>
      <div className='settings-username-title'>
        <UserInitialThumbnail clientId={clientId} />
        <span className='username'>{username}</span>
      </div>
      <Menu text vertical>
        <Menu.Item
          as={Link}
          to={`/client/${clientId}/settings`}
          name='settings'
          active={currentPage === 'settings'}
          onClick={() => logAction({ type: 'click-personal-settings' })}
        >
          {t('settings.personalSettings.title')}
        </Menu.Item>
        {user?.isAdmin && (
          <Menu.Item
            as={Link}
            to={`/client/${clientId}/permissions`}
            name='permissions'
            active={currentPage === 'permissions'}
            onClick={() => logAction({ type: 'click-permissions-settings' })}
          >
            {t('settings.permissions.title')}
          </Menu.Item>
        )}
        {user?.isAdmin && (
          <Menu.Item
            as={Link}
            to={`/client/${clientId}/customization-parameters`}
            name='configuration-parameters'
            active={currentPage === 'configuration-parameters'}
            onClick={() =>
              logAction({ type: 'click-configuration-parameters-settings' })
            }
          >
            {t('settings.configurationParameters.title')}
          </Menu.Item>
        )}
        {configurationParams?.shouldHideCompanyConfigurationSettings !==
          'true' && (
          <Menu.Item
            as={Link}
            to={`/client/${clientId}/company-settings`}
            name='company-settings'
            active={currentPage === 'company-settings'}
            onClick={() => logAction({ type: 'click-company-settings' })}
          >
            {t('settings.companySettings.title')}
          </Menu.Item>
        )}
        <Menu.Item
          as={Link}
          to={`/client/${clientId}/email`}
          name='emailSettings'
          active={currentPage === 'email'}
          onClick={() => logAction({ type: 'click-email-settings' })}
        >
          {t('settings.emailingSection.header')}
        </Menu.Item>
        <Menu.Item
          as={Link}
          to={`/client/${clientId}/users`}
          name='users'
          active={currentPage === 'users'}
          onClick={() => logAction({ type: 'click-collaborators-settings' })}
        >
          {t('collaborators.header')}
        </Menu.Item>
        <Menu.Item
          as={Link}
          to={`/client/${clientId}/senders`}
          name='sendersSettings'
          active={currentPage === 'senders'}
          onClick={() => logAction({ type: 'click-senders-settings' })}
        >
          {t('settings.senders.title')}
        </Menu.Item>
        <Divider className='settings-menu-divider' />
        {permissions?.careerPage &&
          configurationParams?.shouldHideCompanyConfigurationSettings !==
            'true' && (
            <Menu.Item
              as={Link}
              to={`/client/${clientId}/careerPage`}
              name='users'
              active={currentPage === 'careerPage'}
              onClick={() => logAction({ type: 'click-careerPage-settings' })}
            >
              {t('header.accountDropdownMenu.careerPage')}
            </Menu.Item>
          )}
        {applicationMode === 'classic' && (
          <Menu.Item
            as={Link}
            to={`/client/${clientId}/evaluationForms`}
            name='evaluationForms'
            active={currentPage === 'evaluationForms'}
            onClick={() =>
              logAction({ type: 'click-evaluationForms-settings' })
            }
          >
            {t('evaluationFormsSettings.header')}
          </Menu.Item>
        )}
        {configurationParams?.shouldHideCompanyConfigurationSettings !==
          'true' && (
          <Menu.Item
            as={Link}
            to={`/client/${clientId}/departments`}
            name='departments'
            active={currentPage === 'departments'}
            onClick={() => logAction({ type: 'click-departments-settings' })}
          >
            {t('header.accountDropdownMenu.departments')}
          </Menu.Item>
        )}
        {applicationMode === 'reveal' &&
          configurationParams?.shouldHideCompanyConfigurationSettings !==
            'true' &&
          permissions.customPipelines &&
          (permissions.salesProjects ? (
            <>
              <Menu.Item
                as={Link}
                to={`/client/${clientId}/pipeline/recruiting`}
                name='pipeline-recruiting'
                active={
                  currentPage === 'pipeline' && missionCategory === 'recruiting'
                }
                onClick={() =>
                  logAction({ type: 'click-custom-fields-settings' })
                }
              >
                {t('header.accountDropdownMenu.recruiting-pipeline')}
              </Menu.Item>
              <Menu.Item
                as={Link}
                to={`/client/${clientId}/pipeline/sales`}
                name='pipeline-sales'
                active={
                  currentPage === 'pipeline' && missionCategory === 'sales'
                }
                onClick={() =>
                  logAction({ type: 'click-custom-fields-settings' })
                }
              >
                {t('header.accountDropdownMenu.sales-pipeline')}
              </Menu.Item>
            </>
          ) : (
            <Menu.Item
              as={Link}
              to={`/client/${clientId}/pipeline`}
              name='pipeline'
              active={currentPage === 'pipeline'}
              onClick={() =>
                logAction({ type: 'click-custom-fields-settings' })
              }
            >
              {t('header.accountDropdownMenu.super-pipeline')}
            </Menu.Item>
          ))}
        {applicationMode === 'reveal' &&
          permissions.companiesDealStates &&
          configurationParams?.shouldHideCompanyConfigurationSettings !==
            'true' && (
            <Menu.Item
              as={Link}
              to={`/client/${clientId}/companies-states`}
              name='companies-states'
              active={currentPage === 'companies-states'}
              onClick={() =>
                logAction({ type: 'click-companies-states-settings' })
              }
            >
              {t('settings.companiesStates.header')}
            </Menu.Item>
          )}
        {applicationMode === 'reveal' &&
          configurationParams?.shouldHideCompanyConfigurationSettings !==
            'true' && (
            <Menu.Item
              as={Link}
              to={`/client/${clientId}/archive-reasons`}
              name='archive-reasons'
              active={currentPage === 'archive-reasons'}
              onClick={() => logAction({ type: 'click-archive-reasons' })}
            >
              {t('header.accountDropdownMenu.archiveReasons')}
            </Menu.Item>
          )}
        {applicationMode === 'reveal' &&
          configurationParams?.shouldHideCompanyConfigurationSettings !==
            'true' && (
            <Menu.Item
              as={Link}
              to={`/client/${clientId}/customFields`}
              name='customFields'
              active={currentPage === 'customFields'}
              onClick={() =>
                logAction({ type: 'click-custom-fields-settings' })
              }
            >
              {t('header.accountDropdownMenu.customFields')}
            </Menu.Item>
          )}
        {applicationMode === 'reveal' &&
          configurationParams?.shouldHideCompanyConfigurationSettings !==
            'true' &&
          (permissions?.canEditCustomTasks || user?.isAdmin) && (
            <Menu.Item
              as={Link}
              to={`/client/${clientId}/custom-tasks`}
              name='customTasks'
              active={currentPage === 'customTasks'}
              onClick={() => logAction({ type: 'click-custom-tasks-settings' })}
            >
              {t('header.accountDropdownMenu.customTasks')}
            </Menu.Item>
          )}
        {applicationMode === 'reveal' &&
          configurationParams?.shouldHideCompanyConfigurationSettings !==
            'true' &&
          (permissions?.sharedNotes || user?.isAdmin) && (
            <Menu.Item
              as={Link}
              to={`/client/${clientId}/custom-activities`}
              name='customActivities'
              active={currentPage === 'customActivities'}
              onClick={() =>
                logAction({ type: 'click-custom-activities-settings' })
              }
            >
              {t('header.accountDropdownMenu.customActivities')}
            </Menu.Item>
          )}
        {applicationMode === 'reveal' &&
          permissions.advancedTemplating &&
          configurationParams?.shouldHideCompanyConfigurationSettings !==
            'true' && (
            <Menu.Item
              as={Link}
              to={`/client/${clientId}/snippets`}
              name='mergeTags'
              active={currentPage === 'snippets'}
              onClick={() => {}}
            >
              {t('reveal.parameters.snippetSettings.title')}
            </Menu.Item>
          )}
        {applicationMode === 'reveal' && permissions.documentTypes && (
          <Menu.Item
            as={Link}
            to={`/client/${clientId}/custom-document-types`}
            name='documentTypes'
            active={currentPage === 'documentTypes'}
            onClick={() => {}}
          >
            {t('reveal.parameters.documentTypesSettings.title')}
          </Menu.Item>
        )}
        {applicationMode === 'reveal' &&
          configurationParams?.shouldHideCompanyConfigurationSettings !==
            'true' && (
            <Menu.Item
              as={Link}
              to={`/client/${clientId}/custom-profile-sources`}
              name='customProfileSources'
              active={currentPage === 'customProfileSources'}
              onClick={() => {}}
            >
              {t('reveal.parameters.customProfileSourcesSettings.title')}
            </Menu.Item>
          )}
        {(applicationMode === 'classic' ||
          (applicationMode === 'reveal' &&
            configurationParams?.shouldHideCompanyConfigurationSettings !==
              'true')) && <Divider className='settings-menu-divider' />}

        {configurationParams?.shouldHideCompanyConfigurationSettings !==
          'true' && (
          <Menu.Item
            as={Link}
            to={`/client/${clientId}/${
              applicationMode === 'reveal' ? 'crm-' : ''
            }integrations`}
            name='integrations'
            active={
              currentPage === 'integrations' ||
              currentPage === 'crm-integrations'
            }
            onClick={() => logAction({ type: 'click-integrations-settings' })}
          >
            {t('header.accountDropdownMenu.integrations')}
          </Menu.Item>
        )}
        {permissions?.dataProviderIntegrations &&
          configurationParams?.shouldHideCompanyConfigurationSettings !==
            'true' &&
          applicationMode === 'reveal' && (
            <Menu.Item
              as={Link}
              to={`/client/${clientId}/providers`}
              name='providers'
              active={currentPage === 'providers'}
              onClick={() =>
                logAction({ type: 'click-data-providers-settings' })
              }
            >
              {t('providers.header')}
            </Menu.Item>
          )}
        {applicationMode === 'reveal' &&
          permissions?.canEditAutomationIntegrations && (
            <Menu.Item
              as={Link}
              to={`/client/${clientId}/automation-integrations`}
              name='automations'
              active={currentPage === 'automations'}
              onClick={() => logAction({ type: 'click-automations-settings' })}
            >
              {t('header.accountDropdownMenu.automations')}
            </Menu.Item>
          )}
        {applicationMode === 'reveal' &&
          permissions?.canUseNootaIntegration && (
            <Menu.Item
              as={Link}
              to={`/client/${clientId}/noota-integration`}
              name='noota'
              active={currentPage === 'noota'}
              onClick={() => logAction({ type: 'click-noota-settings' })}
            >
              {t('header.accountDropdownMenu.noota')}
            </Menu.Item>
          )}
        {configurationParams?.shouldHideCompanyConfigurationSettings !==
          'true' && (
          <>
            <Menu.Item
              as={Link}
              to={`/client/${clientId}/calendar`}
              name='calendar'
              active={currentPage === 'calendar'}
              onClick={() => logAction({ type: 'click-calendar-settings' })}
            >
              {t('header.accountDropdownMenu.calendarIntegrations')}
            </Menu.Item>
            <Menu.Item
              as={Link}
              to={`/client/${clientId}/phone`}
              name='phoneSettings'
              active={currentPage === 'phone'}
              onClick={() => logAction({ type: 'click-phone-settings' })}
            >
              {t('settings.texting.title')}
            </Menu.Item>
            <Menu.Item
              as={Link}
              to={`/client/${clientId}/slackIntegration`}
              name='slackIntegration'
              active={currentPage === 'slackIntegration'}
              onClick={() =>
                logAction({ type: 'click-slack-integration-settings' })
              }
            >
              {t('header.accountDropdownMenu.slackIntegration')}
            </Menu.Item>
          </>
        )}
        <Menu.Item
          as={Link}
          to={`/client/${clientId}/plugin`}
          name='plugin'
          active={currentPage === 'plugin'}
          onClick={() => logAction({ type: 'click-plugin-settings' })}
        >
          {t('header.accountDropdownMenu.pluginSettings')}
        </Menu.Item>
        {applicationMode === 'reveal' && !permissions?.unipile && (
          <Menu.Item
            as={Link}
            to={`/client/${clientId}/linkedin`}
            name='linkedin'
            active={currentPage === 'linkedin'}
            onClick={() =>
              logAction({ type: 'click-linkedin-accounts-settings' })
            }
          >
            {t('settings.linkedinAccounts.title')}
          </Menu.Item>
        )}
        <Menu.Item
          as={Link}
          to={`/client/${clientId}/${
            applicationMode === 'reveal' ? 'crm-' : ''
          }notifications`}
          name='notifications'
          active={
            currentPage === 'notifications' ||
            currentPage === 'crm-notifications'
          }
          onClick={() => logAction({ type: 'click-notifications-settings' })}
        >
          {t('header.accountDropdownMenu.notifications')}
        </Menu.Item>
        <Divider className='settings-menu-divider' />
        {applicationMode === 'reveal' && (
          <Menu.Item
            as={Link}
            to={`/client/${clientId}/compliance`}
            name='compliance'
            active={currentPage === 'compliance'}
            onClick={() => logAction({ type: 'click-compliance-settings' })}
          >
            {t('header.accountDropdownMenu.compliance')}
          </Menu.Item>
        )}
        {applicationMode === 'classic' && (
          <Menu.Item
            as={Link}
            to={`/client/${clientId}/privacy`}
            name='privacy'
            active={currentPage === 'privacy'}
            onClick={() => logAction({ type: 'click-privacy-settings' })}
          >
            {t('settings.privacySettings.title')}
          </Menu.Item>
        )}

        <Menu.Item
          as={Link}
          to={`/client/${clientId}/legal`}
          name='legalDocumentation'
          active={currentPage === 'legal'}
          onClick={() => logAction({ type: 'click-legal-documentation' })}
        >
          {t('header.accountDropdownMenu.legalDocumentation')}
        </Menu.Item>

        <Divider className='settings-menu-divider' />

        {emailApi &&
          emailApi.onTriggerChangeAccount &&
          (emailApi.currentEmailType === 'outlook' ? (
            <Menu.Item
              onClick={() => {
                emailApi.onTriggerChangeAccount();
                logAction({ type: 'click-email-change-account' });
              }}
            >
              {t('header.accountDropdownMenu.switchEmailAccounts')}
            </Menu.Item>
          ) : (
            <>
              <Menu.Item
                onClick={() => {
                  emailApi.onTriggerChangeAccount('gmail');
                  logAction({ type: 'click-gmail-change-account' });
                }}
              >
                {emailApi.currentEmailType === 'gmail'
                  ? t('header.accountDropdownMenu.switchGmailAccount')
                  : t('header.accountDropdownMenu.useGmailAccount')}
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  emailApi.onTriggerChangeAccount('outlook');
                  logAction({ type: 'click-outlook-change-account' });
                }}
              >
                {emailApi.currentEmailType === 'outlook'
                  ? t('header.accountDropdownMenu.switchOutlookAccount')
                  : t('header.accountDropdownMenu.useOutlookAccount')}
              </Menu.Item>
            </>
          ))}
      </Menu>
    </div>
  );
};

export default _.compose(
  withActionLogger,
  withClientDetails,
  withClientBilling,
  withUserFromJWToken,
  withEmailContextConsumer,
  withTranslation('translations'),
)(SettingsMenu);
